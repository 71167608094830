<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Indicadores</h2>
    <ComponenteFiltro class="filter" :multiple="true" :branch="false" />
    <div class="dashboard pt-14 mt-14">
      <v-row>
        <v-col cols="12">
          <ChartOne />
        </v-col>
        <v-col cols="12">
          <ChartTwo />
        </v-col>
        <v-col cols="12">
          <ChartThree />
        </v-col>
        <!-- <v-col cols="12">
          <ComponenteDashboard />
        </v-col> -->
      </v-row>
    </div>
  </v-main>
</template>
<script>
import ComponenteFiltro from './components/ComponenteFiltro.vue';

import ChartOne from './ChartOne.vue';
import ChartThree from './ChartThree.vue';
import ChartTwo from './ChartTwo.vue';

export default {
  name: 'Dashboard',

  components: {
    ComponenteFiltro,
    ChartOne,
    ChartTwo,
    ChartThree,
  },
};
</script>

<style scoped>
.filter {
  z-index: 3;
  position: relative;
  width: calc(100vw);
  max-width: 1276px;
}

</style>
