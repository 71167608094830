<template>
  <div>
    <v-card class="cardEspecifico">
      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12" sm="3">
            <v-autocomplete
              :value="filter.establishments"
              :items="establishmentsParsed"
              item-text="socialName"
              :item-text="(item) => concatenatedText(item)"
              item-value="cnpjFilial"
              label="Estabelecimentos"
              placeholder="Selecione uma opção"
              no-data-text="Selecione uma empresa primeiro"
              dense
              outlined
              append-icon="mdi-chevron-down"
              multiple
              rounded
              clearable
              hide-details
              persistent-placeholder
              @input="(items) => changeFilter({ prop: 'establishments', value: items })"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  {{ `${filter.establishments.length} selecionado${filter.establishments.length > 1 ? 's' : ''}` }}
                </span>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item #default="{ active }" v-bind="attrs" :class="{ 'pl-10': item.group }" v-on="on">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.socialName"></v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.stateCode || 'Sem dado' }} - {{ item.city || 'Sem dado' }} ({{
                        item.cnpjFilial || 'Sem dado'
                      }})
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="3">
            <v-autocomplete
              :style="'max-width: 256px; margin-left:16px; max-height; 40px !important;height: 40px !important'"
              dense
              outlined
              append-icon="mdi-chevron-down"
              multiple
              hide-no-data
              v-model="filter.categoryCodeForFilter"
              label="Categorias"
              persistent-placeholder
              placeholder="Selecione uma opção"
              :items="categoryCode"
              :item-text="(item) => (item.subtitle ? item.subtitle : concatenatedText(item))"
              @input="(items) => changeCategory(items)"
              :item-value="(item) => item"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  {{
                    `${filter.categoryCodeForFilter.filter((e) => e.grupo).length} selecionado${
                      filter.categoryCodeForFilter.filter((e) => e.grupo).length > 1 ? 's' : ''
                    }`
                  }}
                </span>
              </template>
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item
                  :style="'max-width: 351px !important;'"
                  #default="{ active }"
                  v-bind="attrs"
                  :class="item.grupo ? 'item-descricao' : 'item-subtitle'"
                  v-on="on"
                >
                  <v-list-item-action>
                    <v-checkbox :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-if="item.descricao">
                      <v-tooltip bottom v-if="item.descricao.length > 28" :max-width="250">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ start_and_end(item.descricao, 28) }}
                          </span>
                        </template>
                        {{ item.descricao }}
                      </v-tooltip>
                      <span v-else>{{ item.descricao }}</span>
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      <v-tooltip bottom v-if="item.subtitle.length > 28">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            {{ start_and_end(item.subtitle, 28) }}
                          </span>
                        </template>
                        {{ item.subtitle }}
                      </v-tooltip>
                      <span v-else> {{ item.subtitle }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="item.codigo" class="item-codigo">
                      Código {{ item.codigo }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              :value="filter.rubrics"
              :multiple="multiple"
              :items="rubrics"
              append-icon="mdi-chevron-down"
              :item-text="(rubric) => `${rubric.codigo} - ${rubric.nome}`"
              item-value="codigo"
              label="Natureza da rubrica"
              placeholder="Selecione uma opção"
              dense
              outlined
              rounded
              clearable
              hide-details
              deletable-chips
              persistent-placeholder
              @input="(items) => changeFilter({ prop: 'rubrics', value: items })"
              @change="setLoadingToFalse()"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  {{ `${filter.rubrics.length} selecionado${filter.rubrics.length > 1 ? 's' : ''}` }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              :value="filter.periods"
              :items="periods"
              label="Período de apuração"
              placeholder="Selecione uma opção"
              append-icon="mdi-chevron-down"
              outlined
              rounded
              dense
              multiple
              clearable
              hide-details
              deletable-chips
              persistent-placeholder
              @input="(items) => changeFilter({ prop: 'periods', value: items })"
              @change="setLoadingToFalse()"
            >
              <template v-slot:selection="{ index }">
                <span v-if="index === 0">
                  {{ `${filter.periods.length} selecionado${filter.periods.length > 1 ? 's' : ''}` }}
                </span>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'DashboardFilter',

  props: {
    multiple: Boolean,
    branch: Boolean,
  },

  data() {
    return {
      filterParam: {},
      cnpjsMatriz: [],
      itemsEstabelecimentos: [],
    };
  },

  computed: {
    ...mapState('dashboard', {
      filter: (state) => state.filter,
      establishments: (state) => state.establishments,
      periods: (state) => state.periods,
      rubrics: (state) => state.rubrics,
      categoryCode: (state) => state.categoryCode,
    }),

    establishmentsParsed() {
      let items = [];
      let groupMatriz = groupBy(this.establishments, 'company');

      groupMatriz = Object.entries(groupMatriz).map(([key, value]) => ({
        company: key,
        establishments: value,
      }));
      groupMatriz = sortBy(groupMatriz, ['company']);
      groupMatriz.forEach((item, index) => {
        if (index > 0) items.push({ divider: true });
        const group = item.company;
        items.push({ header: group });
        item.establishments.forEach((innerItem) => {
          innerItem.group = group;
          items.push(innerItem);
        });
      });

      return items;
    },
  },

  watch: {
    filter() {
      this.applyFilterOnRoute();
    },
  },

  created() {
    this.$store.dispatch('dashboard/init', this.getParamsForFilter());
  },

  methods: {
    ...mapActions('dashboard', ['changeFilter', 'changeCategory', 'setLoadingToFalse']),

    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    async applyFilterOnRoute() {
      let filter = { query: this.filter };
      await this.$router.replace(filter);
    },

    getParamsForFilter() {
      const filter = {};

      if (!this.isEmpty(this.$route.query)) {
        let params = this.$route.query;
        let competencias = params.periods;

        if (Array.isArray(competencias)) {
          filter.periods = competencias.map((item) => parseInt(item));
        } else if (!Array.isArray(competencias)) {
          filter.periods = [parseInt(competencias)];
        } else {
        }

        let estabelecimentos = params.establishments;

        if (Array.isArray(estabelecimentos)) {
          filter.establishments = estabelecimentos.map((i) => i);
        } else if (!Array.isArray(estabelecimentos) && this.multiple === true) {
          filter.establishments = [estabelecimentos].map((i) => i);
        } else if (!Array.isArray(estabelecimentos) && this.multiple != true) {
          filter.establishments = estabelecimentos;
        } else {
        }

        let arraynatu = params.rubrics;

        if (Array.isArray(arraynatu)) {
          filter.rubrics = arraynatu.map((i) => Number(i));
        } else if (!Array.isArray(arraynatu) && this.multiple === true) {
          filter.rubrics = [arraynatu].map((i) => Number(i));
        } else if (!Array.isArray(arraynatu) && this.multiple != true) {
          filter.rubrics = Number(arraynatu);
        } else {
          console.log('error on selection');
        }
      }

      return filter;
    },
    start_and_end(str, lengthStr) {
      str = this.removeText('Contribuinte individual - ', str);

      if (str.length > lengthStr) {
        return str.substr(0, lengthStr) + '...';
      }
      return str;
    },
    removeText(textForRemove, str) {
      return str.replace(new RegExp(textForRemove, 'g'), '');
    },
    concatenatedText(obj) {
      let concatenatedText = '';

      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'string') {
            concatenatedText += value;
          }
          if (typeof value === 'number') {
            concatenatedText += value.toString();
          }
        }
      }
      return concatenatedText.toLowerCase();
    },
  },
};
</script>
<style scoped>
::v-deep .v-input__control {
  max-height: 40px !important;
}
.item-subtitle {
  background-color: #f7f7fd;
  color: #182026;
}
.item-subtitle span {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}
.item-descricao {
  padding-left: 40px !important;
  color: #182026;
}
.item-descricao span {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
.item-descricao-inative {
  padding-left: 40px !important;
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
}
.item-subtitle-inative {
  background-color: #e7e7fa !important;
  color: #8686a2 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.item-subtitle-inative span {
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
}
.item-codigo {
  color: #52526b;
  font-size: 14px;
  font-weight: 400;
}

.v-list-item__subtitle.item-codigo {
  color: #52526b !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.v-list-item__subtitle.item-codigo-inative {
  color: #8686a2 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
::v-deep .mdi-checkbox-blank-outline {
  color: var(--v-primary-base);
}
.autocomplete1 {
}
</style>
