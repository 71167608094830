var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Indicadores"),
      ]),
      _c("ComponenteFiltro", {
        staticClass: "filter",
        attrs: { multiple: true, branch: false },
      }),
      _c(
        "div",
        { staticClass: "dashboard pt-14 mt-14" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [_c("ChartOne")], 1),
              _c("v-col", { attrs: { cols: "12" } }, [_c("ChartTwo")], 1),
              _c("v-col", { attrs: { cols: "12" } }, [_c("ChartThree")], 1),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }