var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c(
            "h3",
            {
              staticClass:
                "pt-5 v-heading text-h6 justify-start align-content-end",
            },
            [_vm._v("Massa salarial")]
          ),
          _c("v-switch", {
            staticClass: "ml-auto justify-end switch-tfa",
            attrs: { inset: "", "hide-details": "" },
            scopedSlots: _vm._u([
              {
                key: "prepend",
                fn: function () {
                  return [
                    _c(
                      "v-label",
                      {
                        staticStyle: {
                          color: "red !important",
                          width: "172px",
                        },
                      },
                      [_vm._v(" Exibir dados acima de 0 ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.switch1,
              callback: function ($$v) {
                _vm.switch1 = $$v
              },
              expression: "switch1",
            },
          }),
        ],
        1
      ),
      _vm.dataIndex
        ? _c(
            "v-card",
            { attrs: { height: 400 } },
            [
              _c(
                "v-card-text",
                { staticStyle: { height: "150px" } },
                [
                  _c("apexCharts", {
                    staticClass: "apexchart",
                    attrs: {
                      width: "100%",
                      height: "350",
                      options: _vm.chartOptions,
                      series: _vm.series,
                      theme: _vm.currentTheme,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-card",
            { attrs: { height: 280 } },
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center h-full" },
                  [_c("no-data")],
                  1
                ),
              ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }