<template>
  <div>
    <div class="d-flex">
      <h3 class="pt-5 v-heading text-h6 justify-start align-content-end">Massa salarial</h3>
      <v-switch class="ml-auto justify-end switch-tfa" v-model="switch1" inset hide-details>
        <template v-slot:prepend>
          <v-label style="color: red !important; width: 172px"> Exibir dados acima de 0 </v-label>
        </template>
      </v-switch>
    </div>

    <v-card :height="400" v-if="dataIndex">
      <v-card-text style="height: 150px">
        <apexCharts
          class="apexchart"
          width="100%"
          height="350"
          :options="chartOptions"
          :series="series"
          :theme="currentTheme"
        />
      </v-card-text>
    </v-card>
    <v-card :height="280" v-else>
      <v-card-text>
        <div class="d-flex justify-center align-center h-full">
          <no-data />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ThemeMixin from '@/mixins/Theme.vue';
import { currencyREAL } from '@/utils/currency';
import dayjs from 'dayjs';
import { mapState, mapGetters } from 'vuex';
import 'dayjs/locale/pt-br';

export default {
  name: 'ChartOne',

  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('./components/NoData.vue'),
  },

  mixins: [ThemeMixin],

  data() {
    return {
      switch1: false,
      chartOptions: {
        chart: {
          id: 'massaSalarial',
          type: 'line',
          url: '/indicadores/evolucao-salarial',
          defaultLocale: 'pt-br',
          locales: [
            {
              name: 'pt-br',
              options: {
                toolbar: {
                  exportToSVG: 'Baixar SVG',
                  exportToPNG: 'Baixar PNG',
                  exportToCSV: 'Baixar CSV',
                  menu: 'Menu',
                  selection: 'Selecionar',
                  selectionZoom: 'Selecionar Zoom',
                  zoomIn: 'Aumentar',
                  zoomOut: 'Diminuir',
                  pan: 'Navegação',
                  reset: 'Reiniciar Zoom',
                },
              },
            },
          ],
          toolbar: {
            tools: {
              download: `<i style="font-size: 21px;" class="mdi mdi-download" aria-hidden="true"></i>`,
            },
          },
        },
        tooltip: {
          fixed: {
            enabled: false,
            position: 'bottomRight',
            offsetX: 180,
            offsetY: 180,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: () => '',
            },
          },
        },

        xaxis: {
          categories: [],
          labels: {
            formatter: function (value) {
              if (value) {
                return dayjs(value).locale('pt-br').format('MMM YYYY');
              } else {
                return '';
              }
            },
          },
        },

        yaxis: {
          labels: {
            formatter: function (value) {
              return currencyREAL(value).format();
            },
          },
        },

        title: {
          text: '',
          align: 'center',
          style: {
            color: '#011640',
          },
        },
      },

      series: [
        {
          name: '',
          data: [],
        },
      ],
    };
  },

  computed: {
    ...mapState('dashboard', {
      loadingDataIndex: (state) => state.loadingDataIndex,
      filter: (state) => state.filter,
      rubrics: (state) => state.rubrics,
      dataIndex: (state) => state.dataIndex,
    }),

    ...mapGetters({
      currentTheme: 'indicadores/getTheme',
    }),
  },

  watch: {
    dataIndex(val) {
      if (val) this.mountData();
    },
    switch1(newValue) {
      if (newValue === true) {
        this.mountDataWithoutZeros();
      } else {
        this.mountData();
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 5000);
  },

  methods: {
    mountData() {
      this.chartOptions.xaxis.categories = this.dataIndex.keys;

      this.series = this.dataIndex.graphOne;
    },

    mountDataWithoutZeros() {
      this.chartOptions.xaxis.categories = this.dataIndex.keys;

      let withoutzeros = this.dataIndex.graphOne.filter((item) => item.data.some((val) => val != 0));

      this.series = withoutzeros;
    },
  },
};
</script>
<style scoped>
.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: white !important;
}

.switch-tfa >>> .v-input--switch__track {
  opacity: 100 !important;
}

.v-input__prepend-outer > label {
  color: var(--v-primary-base);
  width: 172px;
  top: 3px;
}
</style>
