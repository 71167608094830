var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "cardEspecifico" },
        [
          _c(
            "v-card-text",
            { staticClass: "text--primary" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          value: _vm.filter.establishments,
                          items: _vm.establishmentsParsed,
                          "item-text": "socialName",
                          "item-text": (item) => _vm.concatenatedText(item),
                          "item-value": "cnpjFilial",
                          label: "Estabelecimentos",
                          placeholder: "Selecione uma opção",
                          "no-data-text": "Selecione uma empresa primeiro",
                          dense: "",
                          outlined: "",
                          "append-icon": "mdi-chevron-down",
                          multiple: "",
                          rounded: "",
                          clearable: "",
                          "hide-details": "",
                          "persistent-placeholder": "",
                        },
                        on: {
                          input: (items) =>
                            _vm.changeFilter({
                              prop: "establishments",
                              value: items,
                            }),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.establishments.length
                                            } selecionado${
                                              _vm.filter.establishments.length >
                                              1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function ({ item, attrs, on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class: { "pl-10": item.group },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ active }) {
                                                return [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          "input-value": active,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            item.socialName
                                                          ),
                                                        },
                                                      }),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.stateCode ||
                                                                  "Sem dado"
                                                              ) +
                                                              " - " +
                                                              _vm._s(
                                                                item.city ||
                                                                  "Sem dado"
                                                              ) +
                                                              " (" +
                                                              _vm._s(
                                                                item.cnpjFilial ||
                                                                  "Sem dado"
                                                              ) +
                                                              ") "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("v-autocomplete", {
                        style:
                          "max-width: 256px; margin-left:16px; max-height; 40px !important;height: 40px !important",
                        attrs: {
                          dense: "",
                          outlined: "",
                          "append-icon": "mdi-chevron-down",
                          multiple: "",
                          "hide-no-data": "",
                          label: "Categorias",
                          "persistent-placeholder": "",
                          placeholder: "Selecione uma opção",
                          items: _vm.categoryCode,
                          "item-text": (item) =>
                            item.subtitle
                              ? item.subtitle
                              : _vm.concatenatedText(item),
                          "item-value": (item) => item,
                        },
                        on: { input: (items) => _vm.changeCategory(items) },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.categoryCodeForFilter.filter(
                                                (e) => e.grupo
                                              ).length
                                            } selecionado${
                                              _vm.filter.categoryCodeForFilter.filter(
                                                (e) => e.grupo
                                              ).length > 1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function ({ item, attrs, on }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class: item.grupo
                                          ? "item-descricao"
                                          : "item-subtitle",
                                        style: "max-width: 351px !important;",
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ active }) {
                                                return [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          "input-value": active,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      item.descricao
                                                        ? _c(
                                                            "v-list-item-title",
                                                            [
                                                              item.descricao
                                                                .length > 28
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                        "max-width": 250,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function ({
                                                                                on,
                                                                                attrs,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {},
                                                                                        "span",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.start_and_end(
                                                                                              item.descricao,
                                                                                              28
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.descricao
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.descricao
                                                                      )
                                                                    ),
                                                                  ]),
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "v-list-item-title",
                                                            [
                                                              item.subtitle
                                                                .length > 28
                                                                ? _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function ({
                                                                                on,
                                                                                attrs,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    _vm._g(
                                                                                      _vm._b(
                                                                                        {},
                                                                                        "span",
                                                                                        attrs,
                                                                                        false
                                                                                      ),
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.start_and_end(
                                                                                              item.subtitle,
                                                                                              28
                                                                                            )
                                                                                          ) +
                                                                                          " "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            item.subtitle
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          item.subtitle
                                                                        )
                                                                    ),
                                                                  ]),
                                                            ],
                                                            1
                                                          ),
                                                      item.codigo
                                                        ? _c(
                                                            "v-list-item-subtitle",
                                                            {
                                                              staticClass:
                                                                "item-codigo",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Código " +
                                                                  _vm._s(
                                                                    item.codigo
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.filter.categoryCodeForFilter,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "categoryCodeForFilter", $$v)
                          },
                          expression: "filter.categoryCodeForFilter",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          value: _vm.filter.rubrics,
                          multiple: _vm.multiple,
                          items: _vm.rubrics,
                          "append-icon": "mdi-chevron-down",
                          "item-text": (rubric) =>
                            `${rubric.codigo} - ${rubric.nome}`,
                          "item-value": "codigo",
                          label: "Natureza da rubrica",
                          placeholder: "Selecione uma opção",
                          dense: "",
                          outlined: "",
                          rounded: "",
                          clearable: "",
                          "hide-details": "",
                          "deletable-chips": "",
                          "persistent-placeholder": "",
                        },
                        on: {
                          input: (items) =>
                            _vm.changeFilter({ prop: "rubrics", value: items }),
                          change: function ($event) {
                            return _vm.setLoadingToFalse()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.rubrics.length
                                            } selecionado${
                                              _vm.filter.rubrics.length > 1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          value: _vm.filter.periods,
                          items: _vm.periods,
                          label: "Período de apuração",
                          placeholder: "Selecione uma opção",
                          "append-icon": "mdi-chevron-down",
                          outlined: "",
                          rounded: "",
                          dense: "",
                          multiple: "",
                          clearable: "",
                          "hide-details": "",
                          "deletable-chips": "",
                          "persistent-placeholder": "",
                        },
                        on: {
                          input: (items) =>
                            _vm.changeFilter({ prop: "periods", value: items }),
                          change: function ($event) {
                            return _vm.setLoadingToFalse()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ index }) {
                              return [
                                index === 0
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            `${
                                              _vm.filter.periods.length
                                            } selecionado${
                                              _vm.filter.periods.length > 1
                                                ? "s"
                                                : ""
                                            }`
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }