<template>
  <div>
    <h3 class="pb-2 v-heading text-h6">Média salarial por faixa</h3>

    <v-card :height="280">
      <v-card-text style="height: 280px">
        <apexCharts
          v-if="dataIndex"
          class="apexchart"
          width="100%"
          height="250"
          :options="chartOptions"
          :series="series"
        />

        <div v-else class="d-flex justify-center align-center h-full">
          <no-data />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { currencyREAL } from '@/utils/currency';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import 'dayjs/locale/pt-br';

export default {
  name: 'ChartTwo',

  components: {
    ApexCharts: () => import('vue-apexcharts'),
    NoData: () => import('./components/NoData.vue'),
  },

  data() {
    return {
      loading: false,
      chartOptions: {
        chart: {
          id: 'mediaSalxFaixa',
          type: 'bar',
          url: '/indicadores/mediasalporfaixa',
          defaultLocale: 'pt-br',
          locales: [
            {
              name: 'pt-br',
              options: {
                toolbar: {
                  exportToSVG: 'Baixar SVG',
                  exportToPNG: 'Baixar PNG',
                  exportToCSV: 'Baixar CSV',
                  menu: 'Menu',
                  selection: 'Selecionar',
                  selectionZoom: 'Selecionar Zoom',
                  zoomIn: 'Aumentar',
                  zoomOut: 'Diminuir',
                  pan: 'Navegação',
                  reset: 'Reiniciar Zoom',
                },
              },
            },
          ],
          toolbar: {
            tools: {
              download: `<i style="font-size: 21px;" class="mdi mdi-download" aria-hidden="true"></i>`,
            },
          },
        },

        xaxis: {
          categories: [],
          labels: {
            formatter: function (value) {
              return dayjs(value).locale('pt-br').format('MMM YYYY');
            },
          },
        },

        yaxis: {
          labels: {
            formatter: function (value) {
              return currencyREAL(value).format();
            },
          },
        },

        dataLabels: {
          formatter: function (value) {
            return currencyREAL(value, { symbol: '', precision: 0 }).format();
          },
          style: {
            colors: ['#000000'],
          },
        },

        title: {
          text: '',
          align: 'center',
          style: {
            color: '#011640',
          },
        },
      },

      series: [
        {
          name: '',
          data: [],
        },
      ],
    };
  },

  computed: {
    ...mapState('dashboard', {
      loadingDataIndex: (state) => state.loadingDataIndex,
      filter: (state) => state.filter,
      dataIndex: (state) => state.dataIndex,
    }),
  },

  watch: {
    dataIndex(val) {
      if (val) this.mountData();
    },
  },

  methods: {
    mountData() {
      this.chartOptions.xaxis.categories = this.dataIndex.keys;

      this.series = this.dataIndex.graphTwo;
    },
  },
};
</script>
