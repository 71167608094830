var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", { staticClass: "pb-2 v-heading text-h6" }, [
        _vm._v("Média salarial por faixa"),
      ]),
      _c(
        "v-card",
        { attrs: { height: 280 } },
        [
          _c(
            "v-card-text",
            { staticStyle: { height: "280px" } },
            [
              _vm.dataIndex
                ? _c("apexCharts", {
                    staticClass: "apexchart",
                    attrs: {
                      width: "100%",
                      height: "250",
                      options: _vm.chartOptions,
                      series: _vm.series,
                    },
                  })
                : _c(
                    "div",
                    {
                      staticClass: "d-flex justify-center align-center h-full",
                    },
                    [_c("no-data")],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }